import _WebPlatformFunctions from "./platform/WebPlatformFunctions";
import _stripe from "./stripe.common";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const WebPlatformFunctions_1 = _WebPlatformFunctions;
const stripe_common_1 = _stripe;
const Stripe = (0, stripe_common_1.createStripe)(new WebPlatformFunctions_1.WebPlatformFunctions());
exports = Stripe;
// expose constructor as a named property to enable mocking with Sinon.JS
exports.Stripe = Stripe;
// Allow use with the TypeScript compiler without `esModuleInterop`.
// We may also want to add `Object.defineProperty(exports, "__esModule", {value: true});` in the future, so that Babel users will use the `default` version.
exports.default = Stripe;
export default exports;
export const __esModule = exports.__esModule;
const _Stripe = exports.Stripe;
export { _Stripe as Stripe };